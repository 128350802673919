import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawBusinessPage: React.FC = () => (
  <Layout id="lawBusiness">
    <SEO title="BusinessLaw" />
    <LawPage
      law="LawBusiness"
      title="fieldLawBusiness"
      serviceTitle="fieldLawBusinessTitle"
      lawTexts={[
        'fieldLawBusinessOne',
        'fieldLawBusinessTwo',
        'fieldLawBusinessThree',
        'fieldLawBusinessFour',
        'fieldLawBusinessFive',
        'fieldLawBusinessSix',
        'fieldLawBusinessSeven',
        'fieldLawBusinessEight',
      ]}
    />
  </Layout>
);

export default LawBusinessPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
